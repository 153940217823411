import { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import Lang from "../layout/Lang";
import DesktopMenu from "../layout/menu/DesktopMenu";
import MobileMenu from "../layout/menu/MobileMenu";
import SocialSidebar from "../layout/menu/SocialSidebar";
import Switch from "../layout/Switch";

const Navbar = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(true);

  const handleClick = () => setShowMobileMenu(!showMobileMenu);

  return (
    <div className="fixed w-full h-20 flex justify-between items-center px-4 bg-slate-200 dark:bg-navbar text-gray-800 dark:text-gray-300 z-10">
      <div>
        <h2 className="font-yellowtail text-3xl">Joe</h2>
      </div>
      <DesktopMenu />

      {/* Hamburger */}
      <div className="md:hidden z-10 flex space-x-5">
        {showMobileMenu && (
          <>
            <Switch />
            <Lang />
          </>
        )}
        <div className="flex justify-center items-center" onClick={handleClick}>
          {showMobileMenu ? <FaBars /> : <FaTimes size={20} />}
        </div>
      </div>

      <MobileMenu showMenu={showMobileMenu} handleClick={handleClick} />
      <SocialSidebar />
    </div>
  );
};

export default Navbar;
