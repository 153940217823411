import { useCallback, useEffect } from "react";
import { useGlobalContext } from "../../../Context";
import Alert from "./Alert";

const time = 5;

const AlertsCenter = () => {
  const { hasAlert, setHasAlert, isError, setIsError, alertMsg, setAlertMsg } =
    useGlobalContext();

  const cleanAlertState = useCallback(() => {
    setHasAlert(false);
    setIsError(false);
    setAlertMsg("");
  }, [setHasAlert, setIsError, setAlertMsg]);

  useEffect(() => {
    if (!hasAlert) return;
    const timeoutId = setTimeout(() => {
      cleanAlertState();
    }, time * 1000);

    return () => clearTimeout(timeoutId);
  }, [cleanAlertState, hasAlert]);

  return (
    <div className="relative inset-20">
      {hasAlert && <Alert isError={isError} message={alertMsg} />}
    </div>
  );
};

export default AlertsCenter;
