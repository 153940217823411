type LegoCardProps = {
  label: string;
  image: string;
};

const LogoCard = ({ label, image }: LegoCardProps) => {
  return (
    <div className="shadow-md dark:shadow-black hover:scale-110 duration-500 rounded-md">
      <img className="w-20 mx-auto" src={image} alt={`${label} Logo`} />
      <p className="my-4">{label}</p>
    </div>
  );
};

export default LogoCard;
