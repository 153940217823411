import create from "./amplify-service";

export interface Language {
  id: number;
  code: string;
  name: string;
  flag: string;
}

const path = "/languages";

export default create(path);
