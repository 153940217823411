import { BsFillPersonLinesFill } from "react-icons/bs";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { SiGmail } from "react-icons/si";
import resumeService from "../../../services/resume-service";
import { useGlobalContext } from "../../../Context";
import { useTranslation } from "react-i18next";

const FILE_NAME = "JoeSaverio.pdf";

const SocialSidebar = () => {
  const { t } = useTranslation();
  const { lang, setIsError, setAlertMsg, setHasAlert } = useGlobalContext();

  const downloadCV = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    resumeService
      .getAll(lang)
      .then((res) => {
        const file = new Blob([res.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.download = FILE_NAME;
        link.href = URL.createObjectURL(file);
        link.click();
        setHasAlert(true);
        setAlertMsg(t("alerts.cv"));
      })
      .catch((err) => {
        setHasAlert(true);
        setIsError(Boolean(err));
        setAlertMsg(t("alerts.error"));
      });
  };

  return (
    <>
      <div className="hidden lg:flex fixed flex-col top-72 left-0">
        <ul>
          <li className="w-36 h-14 flex justify-between items-center -ml-20 hover:-ml-2 duration-300 bg-linkedin">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href="https://www.linkedin.com/in/joe-saverio/"
            >
              LinkedIn <FaLinkedin size={30} />
            </a>
          </li>
          <li className="w-36 h-14 flex justify-between items-center -ml-20 hover:-ml-2 duration-300 bg-github">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href="https://github.com/JoeSvr95"
            >
              Github <FaGithub size={30} />
            </a>
          </li>
          <li className="w-36 h-14 flex justify-between items-center -ml-20 hover:-ml-2 duration-300 bg-gmail">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href="mailto:j.saverio95@gmail.com"
            >
              E-mail <SiGmail size={30} />
            </a>
          </li>
          <li className="w-36 h-14 flex justify-between items-center -ml-20 hover:-ml-2 duration-300 bg-[#565f69]">
            <a
              className="flex justify-between items-center w-full text-gray-300"
              href="/"
              onClick={downloadCV}
            >
              Resume <BsFillPersonLinesFill size={30} />
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SocialSidebar;
