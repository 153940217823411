import create from "./amplify-service";

export interface ContactMessage {
  name: string;
  email: string;
  message: string;
}

const path = "/contact";

export default create(path);
