import LogoCard from "../layout/cards/LogoCard";
import { useTranslation } from "react-i18next";
import useSkills from "../../hooks/useSkills";

const Skills = () => {
  const { t } = useTranslation();
  const { skills } = useSkills();

  return (
    <div
      id="skills"
      className="w-full h-screen bg-neutral-100 dark:bg-background text-gray-600 dark:text-gray-300"
    >
      <div className="max-w-5xl mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div>
          <p className="text-4xl font-bold inline border-b-4 border-blue-500 dark:border-pink-600">
            {t("skills.title")}
          </p>
          <p className="py-4">{t("skills.description")}</p>
        </div>
        {/* All this should be a card component */}
        <div className="w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8">
          {skills.map((skill, index) => (
            <LogoCard key={index} label={skill.label} image={skill.image} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Skills;
