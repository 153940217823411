import contactService, { ContactMessage } from "../../services/contact-service";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useGlobalContext } from "../../Context";
import { z } from "zod";

const schema = z.object({
  name: z
    .string()
    .min(3, { message: "contact.errors.name.min" })
    .max(10, { message: "contact.errors.name.max" }),
  email: z.string().email({ message: "contact.errors.email" }),
  message: z
    .string()
    .min(10, { message: "contact.errors.message.min" })
    .max(50, { message: "contact.errors.message.max" }),
});

type ContactFormData = z.infer<typeof schema>;

const Contact = () => {
  const { t } = useTranslation();
  const { setIsError, setAlertMsg, setHasAlert } = useGlobalContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactFormData>({ resolver: zodResolver(schema) });

  const sendSNSNotification = (data: ContactFormData) => {
    contactService
      .getWithParams<ContactMessage>(data)
      .then((res) => {
        setHasAlert(true);
        setAlertMsg(t("alerts.sendText.success"));
      })
      .catch((err) => {
        setHasAlert(true);
        setIsError(Boolean(err));
        console.log(err);
        setAlertMsg(t("alerts.sendText.error"));
      });
  };

  return (
    <div
      id="contact"
      className="w-full h-screen bg-neutral-100 dark:bg-background flex justify-center items-center p-4"
    >
      <form
        method="POST"
        action=""
        className="flex flex-col max-w-xl w-full"
        onSubmit={handleSubmit(sendSNSNotification)}
      >
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 border-blue-500 text-gray-600 dark:border-pink-600 dark:text-gray-300">
            {t("contact.title")}
          </p>
          <p className="text-gray-800 dark:text-gray-300 py-4">
            {t("contact.description")}
          </p>
        </div>
        <input
          {...register("name")}
          className="bg-gray-200 p-2"
          type="text"
          placeholder={t("contact.form.name")}
          name="name"
        />
        {errors.name && (
          <p className="text-red-500 dark:text-orange-500">
            {t(errors.name.message ?? "")}
          </p>
        )}
        <div className="my-4">
          <input
            {...register("email")}
            className="w-full p-2 bg-gray-200"
            type="email"
            placeholder={t("contact.form.email")}
            name="email"
          />
          {errors.email && (
            <p className="text-red-500 dark:text-orange-500">
              {t(errors.email.message ?? "")}
            </p>
          )}
        </div>
        <textarea
          {...register("message")}
          className="bg-gray-200 p-2"
          name="message"
          placeholder={t("contact.form.message")}
          rows={10}
        ></textarea>
        {errors.message && (
          <p className="text-red-500 dark:text-orange-500">
            {t(errors.message.message ?? "")}
          </p>
        )}

        <button className="text-black dark:text-white border-2 border-gray-800 dark:border-white hover:bg-blue-200 hover:border-blue-200 hover:dark:bg-pink-600 hover:dark:border-pink-600 px-4 py-3 my-8 mx-auto flex items-center">
          {t("contact.button.submit")}
        </button>
      </form>
    </div>
  );
};

export default Contact;
