export enum SuppotedLanguages {
  Spanish = "es",
  English = "en",
}

// Gets user's preferred language. If not supported defaults to english
export function getNavigatorLanguage(languages: readonly string[]) {
  console.log("getting called");
  const prefLanguage = languages[0];

  const langCode = prefLanguage.includes("-")
    ? prefLanguage.split("-")[0]
    : prefLanguage;

  return Object.values(SuppotedLanguages).includes(
    langCode as SuppotedLanguages
  )
    ? langCode
    : "en";
}
