import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";

type MobileMenuProps = {
  showMenu: boolean;
  handleClick: () => void;
};

const MobileMenu = ({ showMenu, handleClick }: MobileMenuProps) => {
  const { t } = useTranslation();

  return (
    <ul
      className={
        showMenu
          ? "hidden"
          : "absolute top-0 left-0 w-full h-screen bg-neutral-100 dark:bg-background flex flex-col justify-center items-center"
      }
    >
      <li className="py-6 text-4xl">
        <Link onClick={handleClick} to="hero" smooth={true} duration={500}>
          {t("navbar.home")}
        </Link>
      </li>
      <li className="py-6 text-4xl">
        <Link onClick={handleClick} to="about" smooth={true} duration={500}>
          {t("navbar.about")}
        </Link>
      </li>
      <li className="py-6 text-4xl">
        <Link onClick={handleClick} to="skills" smooth={true} duration={500}>
          {t("navbar.skills")}
        </Link>
      </li>
      <li className="py-6 text-4xl">
        <Link onClick={handleClick} to="work" smooth={true} duration={500}>
          {t("navbar.work")}
        </Link>
      </li>
      <li className="py-6 text-4xl">
        <Link onClick={handleClick} to="contact" smooth={true} duration={500}>
          {t("navbar.contact")}
        </Link>
      </li>
    </ul>
  );
};

export default MobileMenu;
