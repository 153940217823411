type IconCardProps = {
  title: string;
  icon: string;
};

const IconCard = ({ title, icon }: IconCardProps) => {
  return (
    <div
      title={title}
      className={`bg-gray-800 dark:bg-gray-300 w-10 h-10 mx-auto ${icon}`}
    ></div>
  );
};

export default IconCard;
