import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";

type AlertProps = {
  isError: boolean;
  message: string;
};

const Alert = ({ isError, message }: AlertProps) => {
  const icon = isError ? <AiFillCloseCircle /> : <AiFillCheckCircle />;
  const style = isError
    ? "bg-slate-200 dark:bg-navbar border-l-4 border-red-500 dark:border-red-600 text-red-500 dark:text-red-400 p-4"
    : "bg-slate-200 dark:bg-navbar border-l-4 border-green-600 dark:border-green-500 text-green-600 dark:text-green-500 p-4";

  return (
    <div className={`fixed right-0 mt-5 z-10 ${style}`} role="alert">
      <div className="flex items-center space-x-3">
        <div className="flex-none">{icon}</div>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default Alert;
