import { BsFillMoonFill } from "react-icons/bs";
import { BsFillSunFill } from "react-icons/bs";
import { useGlobalContext } from "../../Context";

type SwitchProps = {
  className?: string;
};

const Switch = ({ className }: SwitchProps) => {
  const { darkMode, setDarkMode } = useGlobalContext();

  const handleClick = () => {
    setDarkMode(!darkMode);
  };

  return (
    <button className={className} onClick={handleClick}>
      {darkMode ? <BsFillSunFill /> : <BsFillMoonFill />}
    </button>
  );
};

export default Switch;
