import { API } from "aws-amplify";
import base_init from "./amplify-config";

type Headers = {
  "Accept-Language": string;
  Accept?: string;
};

interface BaseInit {
  headers: Headers;
  response: boolean;
  responseType?: string;
}

const API_NAME = "MySiteApi";

class HttpAWSService {
  api: string;
  endpoint: string;
  init: BaseInit;

  constructor(endpoint: string, init?: BaseInit) {
    this.api = API_NAME;
    this.endpoint = endpoint;
    if (init) this.init = init;
    else this.init = base_init;
  }

  getAll(lang?: string) {
    if (lang) this.init.headers["Accept-Language"] = lang;
    return API.get(this.api, this.endpoint, this.init);
  }

  getWithParams<T>(payload: T) {
    return API.get(this.api, this.endpoint, {
      queryStringParameters: {
        ...payload,
      },
    });
  }
}

const create = (endpoint: string, init?: BaseInit) =>
  new HttpAWSService(endpoint, init);

export default create;
