import { useEffect, useState } from "react";
import langService, { Language } from "../services/languages-service";
import { API } from "aws-amplify";

const useLanguages = () => {
  const [languages, setLanguages] = useState<Language[]>([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const request = langService.getAll();
    request
      .then((res) => {
        setLanguages(res.data);
        setLoading(false);
      })
      .catch((err) => {
        if (API.isCancel(err)) return;
        setError(err.message);
        setLoading(false);
      });

    return () => {
      API.cancel(request);
    };
  }, []);

  return { languages, error, loading, setLanguages, setError };
};

export default useLanguages;
