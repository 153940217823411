import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import AppProvider from "./Context";
import { Amplify } from "aws-amplify";
import Loading from "./components/layout/Loading";
import awsconfig from "./aws-exports";
import "./i18n";

Amplify.configure(awsconfig);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <AppProvider>
        <App />
      </AppProvider>
    </Suspense>
  </React.StrictMode>
);
