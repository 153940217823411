import create from "./amplify-service";

export interface Job {
  id: number;
  lang: string;
  name: string;
  description: string;
  url: string;
  image: string;
  currentJob: boolean;
}

const path = "/jobs";

export default create(path);
