import { useTranslation } from "react-i18next";

type JobCardProps = {
  label: string;
  description: string;
  buttonLabel: string;
  image: string;
  url: string;
  isCurrentJob?: boolean;
};

const JobCard = ({
  label,
  description,
  buttonLabel,
  image,
  url,
  isCurrentJob,
}: JobCardProps) => {
  const { t } = useTranslation();
  return (
    <div className="shadow-md dark:shadow-black rounded-lg">
      <div
        style={{ backgroundImage: `url(${image})` }}
        className="group container rounded-md flex justify-center items-center mx-auto content-div"
      >
        <div className="flex h-full w-full rounded-md bg-gradient-to-r from-fuchsia-600 to-pink-600 text-center align-middle transition-opacity duration-200 ease-in opacity-0 hover:opacity-100">
          <div className="m-auto">
            <p className="text-3xl font-bold text-white">{label}</p>
            <p className="mx-2 py-5 text-white">{description}</p>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center">
        {isCurrentJob && (
          <div
            className="w-3 h-3 bg-rose-500 dark:bg-pink-600 rounded-full hover:scale-125 duration-200"
            title={t("work.currentJob")}
          ></div>
        )}
        <p className="px-2 py-3 m-2">
          <a
            className="hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-0.5 before:origin-right before:transition-transform before:duration-300 before:scale-x-0 before:bg-blue-500 before:dark:bg-pink-600 before:absolute before:left-0 before:bottom-0"
            href={url}
          >
            {buttonLabel}
          </a>
        </p>
      </div>
    </div>
  );
};

export default JobCard;
