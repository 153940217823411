import AlertsCenter from "./components/layout/alerts/AlertsCenter";
import About from "./components/pages/About";
import Contact from "./components/pages/Contact";
import Home from "./components/pages/Hero";
import Navbar from "./components/pages/Navbar";
import Skills from "./components/pages/Skills";
import Work from "./components/pages/Work";
import { useGlobalContext } from "./Context";

function App() {
  const { darkMode } = useGlobalContext();

  if (darkMode) document.documentElement.classList.add("dark");
  else document.documentElement.classList.remove("dark");

  return (
    <>
      <Navbar />
      <AlertsCenter />
      <Home />
      <About />
      <Skills />
      <Work />
      <Contact />
    </>
  );
}

export default App;
