import { HiArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import { ReactComponent as Flag } from "../../assets/svgs/ecuador.svg";
import MyPicture from "../../assets/me.jpeg";

const Hero = () => {
  const { t } = useTranslation();

  document.title = t("head.title");

  return (
    <div
      id="hero"
      className="w-full h-screen bg-neutral-100 dark:bg-background"
    >
      <div className="max-w-5xl mx-auto px-8 flex flex-col justify-center h-full">
        {/* Mobile */}
        <div className="md:hidden">
          <div className="flex flex-wrap justify-center">
            <div className="w-8/12 sm:w-4/12 py-10">
              <img
                className="rounded-full max-w-full h-auto align-middle border-4 border-blue-500 dark:border-pink-600"
                src={MyPicture}
                alt="Joe Saverio"
              />
            </div>
          </div>
        </div>
        {/* Desktop */}
        <div className="flex flex-row items-end space-x-3">
          <div className="flex flex-col">
            <p className="text-gray-600 dark:text-pink-600">
              {t("hero.greeting")}
            </p>
            <h1 className="text-4xl sm:text-7xl font-bold text-gray-800 dark:text-gray-200">
              Joe Saverio
            </h1>
          </div>
          <Flag title="Ecuador" className="h-9 w-9 md:h-14 md:w-14" />
          {/* Image */}
          <div className="hidden md:block w-6/12 sm:w-4/12 px-28 flex-auto">
            <img
              className="rounded-full max-w-full h-auto align-middle border-4 lg:border-4 border-blue-500 dark:border-pink-600"
              src={MyPicture}
              alt="Joe Saverio"
            />
          </div>
        </div>
        <h2 className="text-4xl sm:text-7xl font-bold text-blue-600 dark:text-gray-400">
          {t("hero.role")}
        </h2>
        <p className="text-gray-600 dark:text-gray-300 py-4 max-w-[700px]">
          {t("hero.description")}
        </p>
        <div>
          <Link
            to="work"
            smooth
            duration={500}
            className="text-gray-800 dark:text-white border-gray-800 dark:border-white group w-fit border-2 px-6 py-3 my-2 flex items-center space-x-4 hover:bg-blue-200 hover:border-blue-200 hover:dark:bg-pink-600 hover:dark:border-pink-600 cursor-pointer"
          >
            <p>{t("hero.button.viewWork")}</p>
            <span className="group-hover:rotate-90 duration-300">
              <HiArrowNarrowRight />
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Hero;
