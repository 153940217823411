import { useEffect, useState } from "react";
import jobsService, { Job } from "../services/jobs-service";
import { API } from "aws-amplify";

const DEF_LANGUAGE = "en";

const useJobs = (lang: string = DEF_LANGUAGE) => {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const request = jobsService.getAll(lang);
    request
      .then((res) => {
        setJobs(res.data);
        setLoading(false);
      })
      .catch((err) => {
        if (API.isCancel(err)) return;
        setJobs([]);
        setError(err.message);
        setLoading(false);
      });

    return () => {
      API.cancel(request);
    };
  }, [lang]);

  return { jobs, error, loading, setJobs, setError };
};

export default useJobs;
