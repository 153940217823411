import create from "./amplify-service";

const path = "/resume";

const init = {
  headers: {
    "Accept-Language": "en",
    Accept: "application/pdf",
  },
  responseType: "blob",
  response: true,
};

export default create(path, init);
