import { BiWorld } from "react-icons/bi";
import { useState, useRef } from "react";
import { useOnClickOutside } from "usehooks-ts";
import { useGlobalContext } from "../../Context";
import { Language } from "../../services/languages-service";
import { useTranslation } from "react-i18next";
import useLanguages from "../../hooks/useLanguages";

const Lang = () => {
  const { languages } = useLanguages();
  const { i18n } = useTranslation();

  const ref = useRef(null);
  const [show, setShow] = useState(false);
  const [language, setLanguage] = useState<Language | null>(null);
  const { setLang } = useGlobalContext();

  const handleClick = () => {
    setShow(!show);
  };

  const handleClickOutside = () => {
    setShow(false);
  };

  const languageSelected = (lang: Language) => {
    setLanguage(lang);
    setLang(lang.code);
    i18n.changeLanguage(lang.code);
    setShow(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <div className="relative">
      <button
        className="w-7 h-7 flex items-center justify-center bg-slate-200 dark:bg-navbar rounded text-sm text-black dark:text-gray-300 hover:ring-1 hover:ring-slate-300 hover:dark:ring-gray-600 focus:outline-none focus:ring-1 focus:ring-blue-400 focus:dark:ring-gray-400"
        onClick={handleClick}
      >
        {language ? language.code.toLocaleUpperCase() : <BiWorld size={15} />}
      </button>
      <div
        ref={ref}
        className={`${
          !show && "hidden"
        } absolute right-0 w-28 bg-slate-200 dark:bg-navbar text-sm rounded-md shadow-md space-y-1 py-2 my-3`}
      >
        {languages.map((lang: Language) => (
          <p
            key={lang.code}
            className="text-gray-800 space-x-2 dark:text-gray-300 px-2 py-1 hover:bg-blue-200 hover:dark:bg-pink-600 cursor-pointer"
            onClick={() => languageSelected(lang)}
          >
            <span>{lang.flag}</span>
            <span>{lang.name}</span>
          </p>
        ))}
      </div>
    </div>
  );
};

export default Lang;
