import { useTranslation } from "react-i18next";
import JobCard from "../layout/cards/JobCard";
import useJobs from "../../hooks/useJobs";
import { useGlobalContext } from "../../Context";

const Work = () => {
  const { t } = useTranslation();
  const { lang } = useGlobalContext();
  const { jobs, error } = useJobs(lang);

  return (
    <div
      id="work"
      className="w-full md:h-screen bg-neutral-100 text-gray-600 dark:bg-background dark:text-gray-300"
    >
      <div className="max-w-5xl mx-auto p-4 flex flex-col justify-center w-full h-full">
        <div className="pb-8">
          <p className="text-4xl font-bold inline border-b-4 text-gray-600 border-blue-500 dark:text-gray-300 dark:border-pink-600">
            {t("work.title")}
          </p>
          <p className="py-6">{t("work.description")}</p>
        </div>

        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-8 px-2 sm:px-0">
          {error ? (
            <p>{error}</p>
          ) : (
            jobs.map((job) => (
              <JobCard
                key={job.id}
                label={job.name}
                description={job.description}
                buttonLabel={t("work.button.visit")}
                image={job.image}
                url={job.url}
                isCurrentJob={job.currentJob}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default Work;
