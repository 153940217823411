import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import translation from "./services/translations-service";
import { getNavigatorLanguage } from "./utils/lang-utils";

const browserLanguege = getNavigatorLanguage(navigator.languages);

const backendOptions = {
  loadPath: "{{lng}}",
  request: (_1: any, url: string, _3: any, callback: any) => {
    try {
      const service = translation(url);
      const request = service.getAll();
      request.then((res) => {
        callback(null, {
          status: 200,
          data: res.data,
        });
      });
    } catch (e) {
      console.log(e);
      callback(null, {
        status: 500,
      });
    }
  },
};

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: browserLanguege,
    interpolation: {
      escapeValue: false,
    },
    backend: backendOptions,
  });
