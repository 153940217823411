import { Link } from "react-scroll";
import Lang from "../Lang";
import Switch from "../Switch";
import { useTranslation } from "react-i18next";

const DesktopMenu = () => {
  const { t } = useTranslation();
  return (
    <div className="hidden md:flex divide-x divide-black/25 dark:divide-white/25">
      <ul className="flex">
        <li>
          <Link to="hero" smooth={true} duration={500}>
            {t("navbar.home")}
          </Link>
        </li>
        <li>
          <Link to="about" smooth={true} duration={500}>
            {t("navbar.about")}
          </Link>
        </li>
        <li>
          <Link to="skills" smooth={true} duration={500}>
            {t("navbar.skills")}
          </Link>
        </li>
        <li>
          <Link to="work" smooth={true} duration={500}>
            {t("navbar.work")}
          </Link>
        </li>
        <li>
          <Link to="contact" smooth={true} duration={500}>
            {t("navbar.contact")}
          </Link>
        </li>
      </ul>
      <div className="flex space-x-3">
        <Switch className="pl-4" />
        <Lang />
      </div>
    </div>
  );
};

export default DesktopMenu;
