import create from "./amplify-service";

export interface Skill {
  name: string;
  image: string;
  label: string;
}

const path = "/skills";

export default create(path);
