import React, { ReactElement, useContext, useState } from "react";
import { getNavigatorLanguage } from "./utils/lang-utils";

type GlobalContent = {
  darkMode: boolean;
  setDarkMode: (value: boolean) => void;
  lang: string;
  setLang: (value: string) => void;
  hasAlert: boolean;
  setHasAlert: (value: boolean) => void;
  isError: boolean;
  setIsError: (value: boolean) => void;
  alertMsg: string;
  setAlertMsg: (value: string) => void;
};

type AppContextProps = {
  children: ReactElement;
};

const AppContext = React.createContext<GlobalContent>({
  darkMode: false,
  setDarkMode: (value: boolean) => {},
  lang: navigator.language,
  setLang: (value: string) => {},
  hasAlert: false,
  setHasAlert: (value: boolean) => {},
  isError: false,
  setIsError: (value: boolean) => {},
  alertMsg: "",
  setAlertMsg: (value: string) => {},
});

const AppProvider = ({ children }: AppContextProps) => {
  const systemTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const defaultLanguage = getNavigatorLanguage(navigator.languages);

  const [darkMode, setDarkMode] = useState(systemTheme);
  const [lang, setLang] = useState(defaultLanguage);
  const [hasAlert, setHasAlert] = useState(false);
  const [isError, setIsError] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  return (
    <AppContext.Provider
      value={{
        darkMode,
        setDarkMode,
        lang,
        setLang,
        hasAlert,
        setHasAlert,
        isError,
        setIsError,
        alertMsg,
        setAlertMsg,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};

export default AppProvider;
