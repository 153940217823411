import { useTranslation } from "react-i18next";
import IconCard from "../layout/cards/IconCard";

const About = () => {
  const { t } = useTranslation();
  const techStack = [
    { title: "TypeScript", icon: "icon-ts" },
    { title: "Python", icon: "icon-py" },
    { title: "React", icon: "icon-react" },
    { title: "Tailwind", icon: "icon-tailwind" },
    { title: "AWS Amplify", icon: "icon-amplify" },
  ];

  return (
    <div
      id="about"
      className="w-full h-screen bg-neutral-100 dark:bg-background text-gray-600 dark:text-gray-300"
    >
      <div className="flex flex-col justify-center items-center w-full h-full">
        <div className="max-w-5xl w-full grid grid-cols-2 gap-8">
          <div className="sm:text-right pb-8 pl-4">
            <p className="text-4xl font-bold inline border-b-4 border-blue-500 dark:border-pink-600">
              {t("about.title")}
            </p>
          </div>
          <div></div>
        </div>
        <div className="max-w-5xl w-full grid sm:grid-cols-2 gap-8 px-4">
          <div className="sm:text-right text-4xl font-bold">
            <p>{t("about.description.part1")}</p>
          </div>
          <div>
            <p>{t("about.description.part2")}</p>
            <div className="w-full grid grid-cols-3 sm:grid-cols-5 gap-4 text-center py-8">
              {techStack.map((tech, index) => (
                <IconCard key={index} title={tech.title} icon={tech.icon} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
